import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import type { ErrorContextType, OperationError } from "types";

/**
 * Operation error context initialization
 */
export const OperationErrorContext = React.createContext<ErrorContextType>({
  setError: () => {}
});

/**
 * Operation error context provider component
 *
 * @param props component properties
 */
const OperationErrorHandler: React.FC = ({ children }) => {
  const [ errorMsg, setErrorMsg ] = React.useState<string>();

  /**
   * Handles error message and tries to print any given error to logs
   *
   * @param message error message
   * @param operationError operation error
   */
  const handleError = async (message: string, operationError: OperationError) => {
    // eslint-disable-next-line no-console
    console.error(operationError);

    setErrorMsg(message);
  };

  /**
   * Component render
   */
  return (
    <OperationErrorContext.Provider value={{ setError: React.useCallback(handleError, []) }}>
      { children }
      <Snackbar open={ errorMsg !== undefined } onClose={ () => setErrorMsg(undefined) }>
        <Alert onClose={ () => setErrorMsg(undefined) } severity="warning">
          <span>{ errorMsg }</span>
        </Alert>
      </Snackbar>
    </OperationErrorContext.Provider>
  );
};

export default OperationErrorHandler;