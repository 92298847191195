import { Alert, Snackbar } from "@mui/material";
import { ErrorContext } from "components/error-handler/error-handler";
import React from "react";
import { useNavigate } from "react-router";
import { AuthenticationButton, AuthenticationInput, AuthenticationHelperText } from "styled/screens/authentication-screens";
import { CredentialError } from "types";
import AuthUtils from "utils/auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AuthenticationLayout from "components/layouts/authentication-layout";
import strings from "localization/strings";

/**
 * Password recovery screen component
 */
const PasswordRecoveryScreen: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(ErrorContext);
  const [ userEmail, setUserEmail ] = React.useState("");
  const [ emailHelperText, setEmailHelperText ] = React.useState("");
  const [ recoverySuccessSnackBar, setRecoverySuccessSnackBar ] = React.useState(false);

  /**
   * Sets error helper text
   * 
   * @param credentialErrors credential errors
   */
  const setRecoveryErrors = (credentialErrors: CredentialError[]) => {
    const emailErrorText = credentialErrors.find(error => error.field?.toLowerCase() === "email")?.code || "";
    setEmailHelperText(emailErrorText);
  };

  /**
   * Send button click handler
   */
  const onSendButtonClick = () => {
    AuthUtils.passwordRecovery(userEmail, `${window.location.origin}/password-reset/`)
      .then(() => {
        setRecoverySuccessSnackBar(true);
      }).catch(errors => {
        if (Array.isArray(errors)) {
          setRecoveryErrors(errors as CredentialError[]);
        } else {
          context.setError(strings.errorHandling.passwordRecoveryFailed, errors);
        }
      });
  };

  /**
   * Close recovery success snackBar handler
   */
  const onRecoverySuccessClose = () => {
    setRecoverySuccessSnackBar(false);
  };

  /**
   * Renders email helper text
   */
  const renderEmailHelperText = (helperText: string) => (
    <AuthenticationHelperText variant="h6">
      { helperText }
    </AuthenticationHelperText>
  );

  /**
   * Renders register success snackBar
   */
  const renderRegisterSuccess = () => (
    <Snackbar open={ recoverySuccessSnackBar } onClose={ onRecoverySuccessClose }>
      <Alert onClose={ onRecoverySuccessClose } severity="info">
        <span>{ strings.authentication.confirmationEmail }</span>
      </Alert>
    </Snackbar>
  );

  return (
    <AuthenticationLayout>
      <AuthenticationInput
        value={ userEmail }
        label={ strings.authentication.email }
        helperText={ renderEmailHelperText(emailHelperText) }
        onChange={ event => setUserEmail(event.target.value) }
      />
      <AuthenticationButton
        color="secondary"
        variant="contained"
        onClick={ onSendButtonClick }
      >
        { strings.generic.send }
      </AuthenticationButton>
      <AuthenticationButton
        color="primary"
        variant="outlined"
        startIcon={ <ArrowBackIcon/> }
        onClick={ () => navigate("/login") }
      >
        { strings.generic.back }
      </AuthenticationButton>
      { renderRegisterSuccess() }
    </AuthenticationLayout>
  );
};

export default PasswordRecoveryScreen;