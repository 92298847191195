import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

/**
 * Styled root component
 */
export const Root = styled(Box, {
  label: "authentication-layout-root"
})(() => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff"
}));

/**
 * Styled login form component
 */
export const Form = styled(FormControl, {
  label: "authentication-layout-form"
})(() => ({
  width: 380
}));

/**
 * Styled logo component
 */
export const LogoContainer = styled(Box, {
  label: "authentication-layout-logo-container"
})(() => ({
  display: "flex",
  justifyContent: "center"
}));

/**
 * Styled logo container component
 */
export const Logo = styled("img", {
  label: "authentication-layout-logo"
})(({ theme }) => ({
  height: 300,
  margin: `${theme.spacing(8)} 0px`,
  color: theme.palette.primary.main
}));