import { Box, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * Styled person avatar component
 */
export const PeronAvatar = styled(Avatar, {
  label: "person-avatar"
})(({ theme }) => ({
  width: 42,
  height: 42,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.text.secondary
}));

/**
 * Styled root component
 */
export const Root = styled(Box, {
  label: "user-info-root"
})(() => ({
  display: "flex",
  alignItems: "center"
}));