import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

/**
 * Styled root component
 */
export const Root = styled(Box, {
  label: "account-confirmation-screen-root"
})(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
}));

/**
 * Styled confirmation result component
 */
export const ConfirmationResultContainer = styled(Box, {
  label: "account-confirmation-screen-confirmation-result"
})(({ theme }) => ({
  height: 200,
  width: "40%",
  backgroundColor: "#fff",
  boxShadow: "0px 3px 3px -2px rgb(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgb(0,0,0,0.12)",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(8)
}));