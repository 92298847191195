import { BulkDeleteProductTypeDocument, CreateProductTypeDocument, ProductTypeInput, ListProductTypesDocument, ProductType, UpdateProductTypeDocument, ListProductTypesQuery, CreateProductMutation, BulkDeleteProductMutation, UpdateProductTypeMutation, ListProductTypesQueryVariables, CreateProductTypeMutationVariables, BulkDeleteProductMutationVariables, UpdateProductTypeMutationVariables } from "api/api";
import * as React from "react";
import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import { DashboardButton, StyledDataGrid } from "styled/screens/dashboard-screen";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import GenericDialog from "components/generic/generic-dialog";
import strings from "localization/strings";
import { useMutation, useQuery } from "@apollo/client";
import WithDataGridDebounceFactory from "components/generic/with-data-grid-debounce";
import { useErrorHandler, useOperationErrorHandler } from "app/hooks";

const PRODUCT_TYPE_PAGE_SIZE = 10;
const WithProductTypeDataGridDebounce = WithDataGridDebounceFactory<ProductType>();

/**
 * Component for product types view
 */
const ProductTypesView: React.FC = () => {
  const productTypesData = useQuery<ListProductTypesQuery, ListProductTypesQueryVariables>(ListProductTypesDocument, { variables: { first: PRODUCT_TYPE_PAGE_SIZE } });
  const [ createProductType, createProductTypeData ] = useMutation<CreateProductMutation, CreateProductTypeMutationVariables>(CreateProductTypeDocument);
  const [ bulkDeleteProductType, deleteProductTypeData ] = useMutation<BulkDeleteProductMutation, BulkDeleteProductMutationVariables>(BulkDeleteProductTypeDocument);
  const [ updateProductType, updateProductTypeData ] = useMutation<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>(UpdateProductTypeDocument);
  const [ creatingProductType, setCreatingProductType ] = React.useState(false);
  const [ productTypesPage, setProductTypesPage ] = React.useState(0);
  const [ newProductType, setNewProductType ] = React.useState<ProductTypeInput>({ name: "" });
  const [ deletingProductType, setDeletingProductType ] = React.useState(false);
  const [ selectedProductTypeIds, setSelectedProductTypeIds ] = React.useState<GridRowId[]>([]);

  // Error handling
  useErrorHandler(productTypesData, strings.errorHandling.productType.list);
  useErrorHandler(createProductTypeData, strings.errorHandling.productType.create);
  useOperationErrorHandler(createProductTypeData.data?.productCreate?.productErrors);
  useErrorHandler(deleteProductTypeData, strings.errorHandling.productType.delete);
  useOperationErrorHandler(deleteProductTypeData.data?.productBulkDelete?.productErrors);
  useErrorHandler(updateProductTypeData, strings.errorHandling.productType.update);
  useOperationErrorHandler(updateProductTypeData.data?.productTypeUpdate?.productErrors);

  /**
   * Handler for product type create confirm
   */
  const onAddProductTypeConfirm = async () => {
    await createProductType({ variables: { input: newProductType } });

    setNewProductType({ name: "" });
    setCreatingProductType(false);
    productTypesData.refetch();
  };

  /**
   * Handler for product type delete confirm
   */
  const onDeleteProductTypeConfirm = async () => {
    await bulkDeleteProductType({ variables: { ids: selectedProductTypeIds.map(id => id.toString()) } });

    setSelectedProductTypeIds([]);
    productTypesData.refetch();
    setDeletingProductType(false);
  };

  /**
   * Handler for new product type name change
   */
  const onNewProductTypeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProductType({ name: event.target.value });
  };

  /**
   * Handler for product type change
   */
  const onProductTypeChange = (updatedProductType: ProductType) => {
    updateProductType({
      variables:
      {
        id: updatedProductType.id,
        input: {
          name: updatedProductType.name,
          hasVariants: updatedProductType.hasVariants
        }
      }
    });
    productTypesData.refetch();
  };

  /**
   * Handler for product types page change
   */
  const onProductTypesPageChange = (newPage: number) => {
    const pageStartCursor = productTypesData.data?.productTypes?.pageInfo.startCursor;
    const pageEndCursor = productTypesData.data?.productTypes?.pageInfo.endCursor;

    if (productTypesPage > newPage) {
      // prev page
      // TODO investigate why fetchmore doesnt work and caching
      productTypesData.refetch({
        first: undefined, last: PRODUCT_TYPE_PAGE_SIZE, before: pageStartCursor, after: undefined
      });
    } else if (productTypesPage < newPage) {
      // next page
      productTypesData.refetch({
        first: PRODUCT_TYPE_PAGE_SIZE, last: undefined, after: pageEndCursor, before: undefined
      });
    }
    setProductTypesPage(newPage);
  };

  /**
   * Renders product type data grid
   */
  const renderProductTypeDataTable = () => {
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: strings.dashboard.productType.dataGridColumn.name,
        width: 280,
        editable: true
      },
      {
        field: "hasVariants",
        type: "boolean",
        headerName: strings.dashboard.productType.dataGridColumn.hasVariant,
        width: 120,
        editable: true
      }
    ];

    const rows = productTypesData?.data?.productTypes?.edges.map(edge => (edge.node as ProductType)) || [];
    const productTypeTotalCount = productTypesData?.data?.productTypes?.totalCount || 0;

    return (
      <Paper>
        <WithProductTypeDataGridDebounce
          columns={ columns }
          rows={ rows }
          onRowChange={ onProductTypeChange }
          component={ params =>
            <StyledDataGrid
              { ...params }
              disableSelectionOnClick
              checkboxSelection
              paginationMode="server"
              autoHeight
              onPageChange={ onProductTypesPageChange }
              pageSize={ PRODUCT_TYPE_PAGE_SIZE }
              rowCount={ productTypeTotalCount }
              loading={ productTypesData.loading }
              onSelectionModelChange={ setSelectedProductTypeIds }
            />
          }
        />
      </Paper>
    );
  };

  /**
   * Renders add product type dialog
   */
  const renderAddProductTypeDialog = () => {
    return (
      <GenericDialog
        error={ false }
        disabled={ !newProductType.name }
        open={ creatingProductType }
        onClose={ () => setCreatingProductType(false) }
        onCancel={ () => setCreatingProductType(false) }
        onConfirm={ onAddProductTypeConfirm }
        title={ strings.dashboard.productType.newProductTypeDialog.title }
        positiveButtonText={ strings.generic.confirm }
        cancelButtonText={ strings.generic.cancel }
      >
        <Box p={ 1 }>
          <TextField
            fullWidth
            color="primary"
            value={ newProductType.name }
            name="name"
            label={ strings.dashboard.productType.dataGridColumn.name }
            onChange={ onNewProductTypeNameChange }
          />
        </Box>
      </GenericDialog>
    );
  };

  /**
   * Renders delete product type dialog
   */
  const renderDeleteProductTypeDialog = () => (
    <GenericDialog
      error={ false }
      open={ deletingProductType }
      onClose={ () => setDeletingProductType(false) }
      onCancel={ () => setDeletingProductType(false) }
      onConfirm={ onDeleteProductTypeConfirm }
      title={ strings.dashboard.productType.deleteProductTypeDialog.title }
      positiveButtonText={ strings.generic.confirm }
      cancelButtonText={ strings.generic.cancel }
    >
      <Typography>
        { strings.dashboard.productType.deleteProductTypeDialog.text }
      </Typography>
    </GenericDialog>
  );

  /**
   * Component render
   */
  return (
    <>
      <Stack
        spacing={ 2 }
        direction="row"
        justifyContent="space-between"
        marginBottom={ 2 }
      >
        <Typography variant="h2">
          { strings.dashboard.productType.title }
        </Typography>
        <Box
          display="flex"
          alignItems="stretch"
        >
          <DashboardButton
            disabled={ !selectedProductTypeIds.length }
            color="error"
            variant="outlined"
            startIcon={ <Delete/> }
            onClick={ () => setDeletingProductType(true) }
            sx={{ mr: 2 }}
          >
            { strings.dashboard.productType.deleteProductType }
          </DashboardButton>
          <DashboardButton
            color="secondary"
            variant="contained"
            startIcon={ <Add/> }
            onClick={ () => setCreatingProductType(true) }
          >
            { strings.dashboard.productType.createProductType }
          </DashboardButton>
        </Box>
      </Stack>
      { renderProductTypeDataTable() }
      { renderAddProductTypeDialog() }
      { renderDeleteProductTypeDialog() }
    </>
  );
};

export default ProductTypesView;