import { ErrorContext } from "components/error-handler/error-handler";
import React from "react";
// @ts-ignore
import queryString from "query-string";
import { PasswordResetResultContainer, Root, PasswordResetContainer } from "styled/screens/password-reset-screen ";
import { AuthenticationInput, AuthenticationHelperText, AuthenticationButton } from "styled/screens/authentication-screens";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { AccountConfirmParam, CredentialError } from "types";
import AuthUtils from "utils/auth";
import AppLayout from "components/layouts/dashboard-layout";
import { Typography } from "@mui/material";
import theme from "theme";
import { useLocation } from "react-router";
import strings from "localization/strings";

/**
 * Register screen component
 */
const PasswordResetScreen: React.FC = () => {
  const context = React.useContext(ErrorContext);
  const location = useLocation();
  const queryParams: AccountConfirmParam = queryString.parse(location.search);
  const [ password, setPassword ] = React.useState("");
  const [ passwordRepeat, setPasswordRepeat ] = React.useState("");
  const [ passwordRepeatHelperText, setPasswordRepeatHelperText ] = React.useState("");
  const [ recoveryError, setRecoveryError ] = React.useState("");
  const [ resettingPassword, setResettingPassword ] = React.useState(false);

  React.useEffect(() => {
    if (queryParams.email && queryParams.token) {
      setResettingPassword(true);
    }
  }, []);

  /**
   * Checks password and passwordRepeat
   * 
   * @param passwordInput password input
   * @param passwordRepeatInput password repeat input
   */
  const checkPasswordRepeat = (passwordInput: string, passwordRepeatInput: string) => {
    if (passwordInput !== passwordRepeatInput) {
      setPasswordRepeatHelperText(strings.authentication.passwordMismatch);
      return false;
    }
    setPasswordRepeatHelperText("");
    return true;
  };

  /**
   * Close register success snackBar handler
   */
  const clearUserInput = () => {
    setPassword("");
    setPasswordRepeat("");
  };

  /**
   * Register button click handler
   */
  const onResetButtonClick = () => {
    if (!checkPasswordRepeat(password, passwordRepeat)) {
      return;
    }

    setResettingPassword(false);
    AuthUtils.resetPassword(queryParams.email, password, queryParams.token)
      .then(() => {
        clearUserInput();
      }).catch(errors => {
        if (Array.isArray(errors)) {
          setRecoveryError(AuthUtils.serializeConfirmationErrors(errors as CredentialError[]));
        } else {
          context.setError(strings.errorHandling.passwordResetFailed, errors);
        }
      });
  };

  /**
   * Password change event handler
   * 
   * @param event event
   */
  const onUserPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordInput = event.target.value;
    checkPasswordRepeat(passwordInput, passwordRepeat);
    setPassword(passwordInput);
  };

  /**
   * Password repeat change event handler
   * 
   * @param event event
   */
  const onUserPasswordRepeatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRepeatInput = event.target.value;
    checkPasswordRepeat(password, passwordRepeatInput);
    setPasswordRepeat(passwordRepeatInput);
  };

  /**
   * Renders helper text
   * 
   * @param helperText helper text
   */
  const renderHelperText = (helperText: string) => (
    <AuthenticationHelperText variant="h6">
      { helperText }
    </AuthenticationHelperText>
  );
  
  /**
   * Renders invalid link 
   */
  const renderInvalidLink = () => (
    <>
      <CloseIcon
        htmlColor={ theme.palette.error.light }
        style={{
          height: 80,
          width: 80,
          paddingRight: theme.spacing(4)
        }}
      />
      <Typography>{ strings.generic.inValidLink }</Typography>
    </>
  );

  /**
   * Renders confirmation 
   */
  const renderConfirmation = () => {
    if (recoveryError) {
      return (
        <>
          <CloseIcon
            htmlColor={ theme.palette.error.light }
            style={{
              height: 80,
              width: 80,
              marginRight: theme.spacing(4)
            }}
          />
          <Typography>{ `${strings.errorHandling.title}: ${recoveryError}` }</Typography>
        </>
      );
    }

    return (
      <>
        <CheckIcon
          htmlColor={ theme.palette.error.light }
          style={{
            height: 80,
            width: 80,
            marginRight: theme.spacing(4)
          }}
        />
        <Typography>{ strings.passwordResetScreen.successful }</Typography>
      </>
    );
  };

  /**
   * Renders content 
   */
  const renderContent = () => {
    if (resettingPassword) {
      return (
        <PasswordResetContainer>
          <AuthenticationInput
            type="password"
            label={ strings.authentication.password }
            value={ password }
            onChange={ onUserPasswordChange }
          />
          <AuthenticationInput
            type="password"
            label={ strings.authentication.passwordRepeat }
            value={ passwordRepeat }
            helperText={ renderHelperText(passwordRepeatHelperText) }
            onChange={ onUserPasswordRepeatChange }
          />
          <AuthenticationButton
            color="secondary"
            variant="contained"
            onClick={ onResetButtonClick }
          >
            { strings.generic.reset }
          </AuthenticationButton>
        </PasswordResetContainer>
      );
    }

    return (
      <PasswordResetResultContainer>
        { (!queryParams.email || !queryParams.token) ? renderInvalidLink() : renderConfirmation() }
      </PasswordResetResultContainer>
    );
  };

  /**
   * Component render
   */
  return (
    <AppLayout>
      <Root>
        { renderContent() }
      </Root>
    </AppLayout>
  );
};

export default PasswordResetScreen;