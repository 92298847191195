import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AccountConfirmParam, CredentialError } from "types";
// @ts-ignore
import queryString from "query-string";
import AppLayout from "components/layouts/dashboard-layout";
import { CircularProgress, Typography } from "@mui/material";
import AuthUtils from "utils/auth";
import { ErrorContext } from "components/error-handler/error-handler";
import { Root, ConfirmationResultContainer } from "styled/screens/account-confirm-screen";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import theme from "theme";
import strings from "localization/strings";

/**
 * Account confirmation screen component
 */
const AccountConfirmScreen: React.FC = () => {
  const location = useLocation();
  const context = React.useContext(ErrorContext);
  const navigate = useNavigate();
  const queryParams: AccountConfirmParam = queryString.parse(location.search);
  const [ authenticating, setAuthenticating ] = React.useState(false);
  const [ authenticationError, setAuthenticationError ] = React.useState("");

  React.useEffect(() => {
    if (!queryParams.email || !queryParams.token) {
      return;
    }

    setAuthenticating(true);

    AuthUtils.confirmAccount(queryParams.email, queryParams.token)
      .then(() => {
        setAuthenticating(false);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch(errors => {
        if (Array.isArray(errors)) {
          setAuthenticationError(AuthUtils.serializeConfirmationErrors(errors as CredentialError[]));
        } else {
          context.setError(strings.errorHandling.accountConfirmFailed, errors);
        }
        setAuthenticating(false);
      });
  }, []);

  /**
   * Renders invalid link 
   */
  const renderInvalidLink = () => (
    <>
      <CloseIcon
        htmlColor={ theme.palette.error.light }
        style={{
          height: 80,
          width: 80,
          paddingRight: theme.spacing(4)
        }}
      />
      <Typography>{ strings.generic.inValidLink }</Typography>
    </>
  );

  /**
   * Renders loading content 
   */
  const renderLoading = () => (
    <>
      <CircularProgress size={ 60 } sx={{ mr: 4 }}/>
      <Typography>{ strings.generic.loading }</Typography>
    </>
  );

  /**
   * Renders confirmation 
   */
  const renderConfirmation = () => {
    if (authenticating) {
      return renderLoading();
    }

    if (authenticationError) {
      return (
        <>
          <CloseIcon
            htmlColor={ theme.palette.error.light }
            style={{
              height: 80,
              width: 80,
              marginRight: theme.spacing(4)
            }}
          />
          <Typography>{ `${strings.errorHandling.title}: ${authenticationError}` }</Typography>
        </>
      );
    }

    return (
      <>
        <CheckIcon
          htmlColor={ theme.palette.success.main }
          style={{
            height: 80,
            width: 80,
            marginRight: theme.spacing(4)
          }}
        />
        <Typography>{ strings.accountConfirmationScreen.emailVerified }</Typography>
      </>
    );
  };

  /**
   * Component render
   */
  return (
    <AppLayout>
      <Root>
        <ConfirmationResultContainer>
          { (!queryParams.email || !queryParams.token) ? renderInvalidLink() : renderConfirmation() }
        </ConfirmationResultContainer>
      </Root>
    </AppLayout>
  );
};

export default AccountConfirmScreen;