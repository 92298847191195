import * as React from "react";
import { useAppSelector, useInterval, useAppDispatch } from "app/hooks";
import AuthUtils from "utils/auth";
import { selectAccessToken, login } from "features/auth-slice";

/**
 * Component for keeping authentication token fresh
 *
 * @param props component properties
 */
const AccessTokenRefresh: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const [ authenticated, setAuthenticated ] = React.useState(false);

  React.useEffect(() => {
    AuthUtils.initAuth()
      .then(token => token && dispatch(login({ accessToken: token })))
      // eslint-disable-next-line no-console
      .catch(console.error)
      .finally(() => setAuthenticated(true));
  }, []);

  // TODO Investigate oidc flow if constantly refreshing is needed
  useInterval(() => {
    AuthUtils.refreshAccessToken(accessToken?.csrf_token)
      .then(refreshedToken => refreshedToken && dispatch(login({ accessToken: refreshedToken })))
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, 1000 * 60);

  /**
   * Component render
   */
  return authenticated ? <>{ children }</> : null;
};

export default AccessTokenRefresh;