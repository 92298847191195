import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { LogoContainer, HeaderToolbar, Logo, Root } from "styled/layout-components/header";
import PersonIcon from "@mui/icons-material/Person";
import siteLogo from "../../resources/svg/Metatavu-icon-black.svg";
import { useNavigate } from "react-router";
import strings from "localization/strings";
import { selectAccessToken } from "features/auth-slice";
import { useAppSelector } from "app/hooks";
import UserInfo from "components/generic/user-info";

/**
 * Header component
 */
const Header: React.FC = () => {
  const navigate = useNavigate();
  const accessToken = useAppSelector(selectAccessToken);

  /**
   * Renders sin in button
   */
  const renderSignInButton = () => (
    <Button
      variant="text"
      color="secondary"
      startIcon={ <PersonIcon/> }
      onClick={ () => navigate("/login") }
    >
      { strings.authentication.signIn }
    </Button>
  );

  /**
   * Component render
   */
  return (
    <Root elevation={ 5 }>
      <HeaderToolbar>
        <LogoContainer onClick={ () => navigate("/") }>
          <Logo alt="Metatavu logo" src={ siteLogo }/>
          <Typography
            color="primary"
            variant="h1"
          >
            Metastore
          </Typography>
        </LogoContainer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          { accessToken?.email ?
            <UserInfo
              userEmail={ accessToken.email }
            />
            :
            renderSignInButton()
          }
        </Box>
      </HeaderToolbar>
    </Root>
  );
};

export default Header;