import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainScreen from "components/screens/main-screen";
import LoginScreen from "./screens/login-screen";
import RegisterScreen from "./screens/register-screen";
import AccountConfirmScreen from "./screens/account-confirm-screen";
import PasswordRecoveryScreen from "./screens/password-recovery";
import PasswordResetScreen from "./screens/password-reset";
import AccessTokenRefresh from "./container/access-token-refresh";
import DashBoardScreen from "./screens/dashboard/dashboard-screen";
import { useAppSelector } from "app/hooks";
import { selectAccessToken } from "features/auth-slice";
import ApolloClientFactory from "app/graphql";
import { ApolloProvider } from "@apollo/client";
import ProductDetailScreen from "./screens/product-detail-screen";

/**
 * Application component
 */
const App: React.FC = () => {
  const accessToken = useAppSelector(selectAccessToken);

  return (
    <AccessTokenRefresh>
      <ApolloProvider client={ ApolloClientFactory.init(accessToken) }>
        <Router>
          <Routes>
            <Route
              path="/"
              element={ <MainScreen/> }
            />
            <Route
              path="/login"
              element={ <LoginScreen/> }
            />
            <Route
              path="/register"
              element={ <RegisterScreen/> }
            />
            <Route
              path="/account-confirm"
              element={ <AccountConfirmScreen/> }
            />
            <Route
              path="/password-recovery"
              element={ <PasswordRecoveryScreen/> }
            />
            <Route
              path="/password-reset"
              element={ <PasswordResetScreen/> }
            />
            <Route
              path="/dashboard/*"
              element={ <DashBoardScreen/> }
            />
            <Route
              path="/:productSlug"
              element={ <ProductDetailScreen/> }
            />
          </Routes>
        </Router>
      </ApolloProvider>
    </AccessTokenRefresh>
  );
};

export default App;