import { Button, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

/**
 * Styled dashboard button
 */
export const DashboardButton = styled(Button, {
  label: "dashboard-screen-button"
})(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));

/**
 * Styled new survey button
 */
export const ContentContainer = styled(Box, {
  label: "dashboard-screen-content-container"
})(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  overflow: "scroll"
}));

/**
 * Styled new dashboard info card
 */
export const DashboardCard = styled(Paper, {
  label: "dashboard-screen-card"
})(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  borderTopLeftRadius: 0
}));

/**
 * Styled dashboard datagrid
 */
export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-booleanCell[data-value='true']": {
    color: theme.palette.success.main
  },
  "& .MuiDataGrid-booleanCell[data-value='false']": {
    color: theme.palette.error.main
  }
}));