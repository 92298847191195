import * as React from "react";
import { Card, CardMedia, CardContent, Box, Typography, Stack } from "@mui/material";
import { ProductCardType } from "types";
import strings from "localization/strings";

/**
 * Component properties
 */
interface Props {
  product: ProductCardType;
  onClick?: () => void;
}

/**
 * User info component
 *
 * @param props component properties
 */
const ProductCard: React.FC<Props> = ({
  product,
  onClick
}) => (
  <Card
    elevation={ 3 }
    onClick={ onClick }
    sx={{
      width: 270,
      cursor: "pointer"
    }}
  >
    <CardMedia
      component="img"
      height="194"
      image={ product.thumbnail?.url || "" }
      alt={ product.thumbnail?.alt || "" }
    />
    <CardContent>
      <Stack
        height={ 90 }
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="subtitle1">
            { product.name }
          </Typography>
          <Typography>
            { product.description }
          </Typography>
        </Box>
        { product.pricing?.priceRange?.start?.gross.amount &&
          <Typography variant="subtitle1">
            { `${strings.generic.from} ${product.pricing?.priceRange?.start?.gross.amount} ${product.pricing?.priceRange?.start?.gross.currency}` }
          </Typography>
        }
      </Stack>
    </CardContent>
  </Card>
);

export default ProductCard;