import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import en from "./en.json";
import fi from "./fi.json";

/**
 * Localized strings
 */
export interface Localized extends LocalizedStringsMethods {

  /**
   * Translations related to generic words
   */
  generic: {
    notImplemented: string;
    back: string;
    loading: string;
    inValidLink: string;
    send: string;
    reset: string;
    confirm: string;
    cancel: string;
    delete: string;
    open: string;
    loadMore: string;
    from: string;
  };

  /**
   * Translations related to authentication words
   */
  authentication: {
    signIn: string;
    register: string;
    signOut: string;
    email: string;
    password: string;
    passwordRepeat: string;
    confirmationEmail: string;
    passwordMismatch: string;
  }

  /**
   * Translations related to error handling
   */
  errorHandling: {
    title: string;
    operationError: string;
    code: string;
    source: string;
    loginFailed: string;
    registerFailed: string;
    accountConfirmFailed: string;
    passwordRecoveryFailed: string;
    passwordResetFailed: string;
    product: {
      create: string;
      list: string;
      delete: string;
      update: string;
      setAvailability: string;
    };
    productType: {
      create: string;
      list: string;
      delete: string;
      update: string;
    };
    category: {
      create: string;
      list: string;
      delete: string;
      update: string;
    };
  };

  /**
   * Translations related to login screen
   */
  loginScreen: {
    newUser: string;
    registerNow: string;
    forgotPassword: string;
  };

  /**
   * Translations related to login screen
   */
  accountConfirmationScreen: {
    emailVerified: string;
  };

  /**
   * Translations related to password reset screen
   */
  passwordResetScreen: {
    successful: string;
  };

  /**
   * Translations related to main screen
   */
  mainScreen: {
    purchase: string;
  };

  /**
   * Translations related to dashboard screen
   */
  dashboard: {
    title: string;
    productType: {
      title: string;
      createProductType: string;
      deleteProductType: string;
      dataGridColumn: {
        name: string;
        slug: string;
        hasVariant: string;
      };
      newProductTypeDialog: {
        title: string;
      };
      deleteProductTypeDialog: {
        title: string;
        text: string;
      };
    };
    product: {
      title: string;
      createProduct: string;
      deleteProduct: string;
      dataGridColumn: {
        name: string;
        sku: string;
        description: string;
        productType: string;
        basePrice: string;
        isPublished: string;
        category: string;
        isAvailable: string;
        availableDate: string;
        visibleInListings: string;
      };
      deleteProductDialog: {
        title: string;
        text: string;
      };
      newProduct: {
        title: string;
      };
      productEdit: {
        productInfo: string;
        availability: string;
      };
    };
    category: {
      title: string;
      createCategory: string;
      deleteCategory: string;
      dataGridColumn: {
        name: string;
      };
      newCategoryDialog: {
        title: string;
      };
      deleteCategoryDialog: {
        title: string;
        text: string;
      };
    };
  };
  
}

/**
 * Initialized localized strings
 */
const strings: Localized = new LocalizedStrings({ en: en, fi: fi });

export default strings;