import * as React from "react";
import strings from "localization/strings";
import { useParams } from "react-router-dom";
import { ProductBySlugDocument, ProductBySlugQuery, UpdateProductMutation, UpdateProductDocument, UpdateProductMutationVariables, ProductBySlugQueryVariables, ListCategoriesQuery, ListCategoriesQueryVariables, ListCategoriesDocument, SetProductAvailabilityMutation, SetProductAvailabilityMutationVariables, SetProductAvailabilityDocument } from "api/api";
import { useMutation, useQuery } from "@apollo/client";
import { Button, FormControlLabel, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material";
import WithDebounce from "components/generic/with-debounce";
import { useErrorHandler, useOperationErrorHandler } from "app/hooks";
import { DashboardCard } from "styled/screens/dashboard-screen";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";

const PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE = 3;

/**
 * Component for products view
 */
const ProductView: React.FC = () => {
  const { productSlug } = useParams();
  const productData = useQuery<ProductBySlugQuery, ProductBySlugQueryVariables>(ProductBySlugDocument, { variables: { slug: productSlug || "" } });
  const categoriesData = useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, { variables: { first: PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE } });
  const [ setProductAvailability, setProductAvailabilityData ] = useMutation<SetProductAvailabilityMutation, SetProductAvailabilityMutationVariables>(SetProductAvailabilityDocument);
  const [ updateProduct, updateProductData ] = useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument);
  const [ categoryCount, setCategoryCount ] = React.useState(PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE);
  const [ availableDate, setAvailableDate ] = React.useState<Date | null>(new Date());
  
  // Error handling
  useErrorHandler(productData, strings.errorHandling.product.list);
  useErrorHandler(updateProductData, strings.errorHandling.product.update);
  useErrorHandler(categoriesData, strings.errorHandling.category.create);
  useOperationErrorHandler(updateProductData.data?.productUpdate?.productErrors);
  useErrorHandler(setProductAvailabilityData, strings.errorHandling.product.setAvailability);
  useOperationErrorHandler(setProductAvailabilityData.data?.productSetAvailabilityForPurchase?.productErrors);

  /**
   * Event Handler set product prop
   * 
   * @param event event
   */
  const onProductPropChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = ({ target }) => {
    if (!productData.data?.product?.id) {
      return;
    }

    const { value, name } = target;

    updateProduct({ variables: { id: productData.data.product.id, input: { [name]: value } } });
  };

  /**
   * Event Handler on available changes
   * 
   * @param event event
   */
  const onProductAvailabilityChange = (isAvailable: boolean, newAvailableDate?: Date | null) => {
    if (!productData.data?.product?.id) {
      return;
    }

    newAvailableDate && setAvailableDate(newAvailableDate);
    setProductAvailability({
      variables: {
        productId: productData.data.product.id, isAvailable: isAvailable, startDate: newAvailableDate && moment(newAvailableDate).format("YYYY-MM-DD")
      }
    });
  };

  /**
   * Event Handler on product publishes
   * 
   * @param event event
   */
  const onProductPublish: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (!productData.data?.product?.id) {
      return;
    }

    const { checked } = target;

    updateProduct({ variables: { id: productData.data.product.id, input: { isPublished: checked, category: productData.data.product.category?.id } } });
  };

  /**
   * Event Handler on product sets visible
   * 
   * @param event event
   */
  const onProductVisible: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (!productData.data?.product?.id) {
      return;
    }

    const { checked, name } = target;

    updateProduct({ variables: { id: productData.data.product.id, input: { [name]: checked } } });
  };

  /**
   * Graphql pagination load more categories
   */
  const onLoadMoreCategory = () => {
    const updatedCategoryCount = categoryCount + PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE;
    categoriesData.refetch({ first: updatedCategoryCount });
    setCategoryCount(updatedCategoryCount);
  };

  /**
   * Renders category select
   */
  const renderCategorySelect = () => (
    categoriesData.data?.categories?.edges.map(edge => (
      <MenuItem value={ edge.node.id } key={ edge.node.id }>
        { edge.node.name }
      </MenuItem>
    ))
  );

  /**
   * Renders load more categories button
   */
  const renderCategoryLoadMore = () => (
    categoriesData.data?.categories?.pageInfo?.hasNextPage &&
    <Button
      fullWidth
      variant="text"
      onClick={ onLoadMoreCategory }
    >
      { strings.generic.loadMore }
    </Button>
  );

  /**
   * Renders textfield with debounce
   * 
   * @param name name
   * @param label label
   * @param onChange onChange
   * @param value value
   */
  const renderWithDebounceTextField = (
    name: string,
    label: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value?: string
  ) => (
    <WithDebounce
      name={ name }
      value={ value }
      label={ label }
      onChange={ onChange }
      component={ props =>
        <TextField { ...props }/>
      }
    />
  );

  /**
   * Renders multiline textfield with debounce
   * 
   * @param name name
   * @param label label
   * @param onChange onChange
   * @param value value
   */
  const renderWithDebounceMultilineTextField = (
    name: string,
    label: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value?: string
  ) => (
    <WithDebounce
      name={ name }
      value={ value }
      label={ label }
      onChange={ onChange }
      component={ props =>
        <TextField
          { ...props }
          multiline
          rows={ 6 }
        />
      }
    />
  );

  /**
   * Renders switch with label
   * 
   * @param name name
   * @param label label
   * @param onChange onChange
   * @param value value
   */
  const renderSwitchWithLabel = (
    name: string,
    label: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value?: boolean
  ) => (
    <FormControlLabel
      label={ label }
      control={
        <Switch
          name={ name }
          checked={ !!value }
          onChange={ onChange }
        />
      }
    />
  );

  /**
   * Render Basic info section
   */
  const renderBasicInfo = () => {
    const productQueryResult = productData.data?.product;

    if (!productQueryResult) {
      return null;
    }

    return (
      <DashboardCard>
        <Stack spacing={ 4 }>
          <Typography variant="h2">
            { strings.dashboard.product.productEdit.productInfo }
          </Typography>
          {
            renderWithDebounceTextField(
              "name",
              strings.dashboard.product.dataGridColumn.name,
              onProductPropChange,
              productQueryResult.name
            )
          }
          {
            renderSwitchWithLabel(
              "isPublished",
              strings.dashboard.product.dataGridColumn.isPublished,
              onProductPublish,
              productQueryResult.isPublished
            )
          }
          {
            renderSwitchWithLabel(
              "visibleInListings",
              strings.dashboard.product.dataGridColumn.visibleInListings,
              onProductVisible,
              productQueryResult.visibleInListings
            )
          }
          <TextField
            disabled
            label="ProductType"
            value={ productQueryResult.productType.name }
          />
          <TextField
            select
            name="category"
            label={ strings.dashboard.product.dataGridColumn.category }
            value={ productQueryResult.category?.id }
            onChange={ onProductPropChange }
          >
            { renderCategorySelect() }
            { renderCategoryLoadMore() }
          </TextField>
          {
            renderWithDebounceMultilineTextField(
              "description",
              strings.dashboard.product.dataGridColumn.description,
              onProductPropChange,
              productQueryResult.description
            )
          }
        </Stack>
      </DashboardCard>
    );
  };

  /**
   * Render availability section
   */
  const renderAvailability = () => {
    const productQueryResult = productData.data?.product;

    if (!productQueryResult) {
      return null;
    }

    return (
      <DashboardCard>
        <Stack spacing={ 4 }>
          <Typography variant="h2">
            { strings.dashboard.product.productEdit.availability }
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={ strings.dashboard.product.dataGridColumn.availableDate }
              value={ availableDate }
              onChange={ newValue => onProductAvailabilityChange(!!productQueryResult.isAvailableForPurchase, newValue) }
              renderInput={ params => <TextField {...params}/> }
            />
          </LocalizationProvider>
          <FormControlLabel
            label={ strings.dashboard.product.dataGridColumn.isAvailable }
            control={
              <Switch
                name="isAvailable"
                checked={ !!productQueryResult.isAvailableForPurchase }
                onChange={ e => onProductAvailabilityChange(e.target.checked) }
              />
            }
          />
        </Stack>
      </DashboardCard>
    );
  };

  /**
   * Component render 
   */
  return (
    <Stack spacing={ 4 }>
      { renderBasicInfo() }
      { renderAvailability() }
    </Stack>
  );
};

export default ProductView;