import { Toolbar, Box, useMediaQuery } from "@mui/material";
import Header from "components/layout-components/header";
import React from "react";
import { Content, ViewContainer } from "styled/layouts/app-layout";
import theme from "theme";

/**
 * Component properties
 */
interface Props {
  sidePenal?: JSX.Element;
}

/**
 * Admin dashboard layout component
 *
 * @param props component properties
 */
const DashboardLayout: React.FC<Props> = ({
  children,
  sidePenal
}) => {
  /**
   * Check if viewport is mobile size
   */
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex" }}>
      <Header/>
      <Content>
        <Toolbar/>
        <ViewContainer direction="row" spacing={ isMobile ? 0 : 2 }>
          { sidePenal }
          { children }
        </ViewContainer>
      </Content>
    </Box>
  );
};

export default DashboardLayout;