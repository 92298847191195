import { BulkDeleteCategoryDocument, CreateCategoryDocument, CategoryInput, ListCategoriesDocument, Category, UpdateCategoryDocument, ListCategoriesQuery, CreateProductMutation, BulkDeleteProductMutation, UpdateCategoryMutation, ListCategoriesQueryVariables, CreateCategoryMutationVariables, BulkDeleteProductMutationVariables, UpdateCategoryMutationVariables } from "api/api";
import * as React from "react";
import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import { DashboardButton, StyledDataGrid } from "styled/screens/dashboard-screen";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import GenericDialog from "components/generic/generic-dialog";
import strings from "localization/strings";
import { useMutation, useQuery } from "@apollo/client";
import WithDataGridDebounceFactory from "components/generic/with-data-grid-debounce";
import { useErrorHandler, useOperationErrorHandler } from "app/hooks";

const PRODUCT_CATEGORY_PAGE_SIZE = 10;
const WithCategoryDataGridDebounce = WithDataGridDebounceFactory<Category>();

/**
 * Component for categories view
 */
const CategoriesView: React.FC = () => {
  const categoriesData = useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, { variables: { first: PRODUCT_CATEGORY_PAGE_SIZE } });
  const [ createCategory, createCategoryData ] = useMutation<CreateProductMutation, CreateCategoryMutationVariables>(CreateCategoryDocument);
  const [ bulkDeleteCategory, deleteCategoryData ] = useMutation<BulkDeleteProductMutation, BulkDeleteProductMutationVariables>(BulkDeleteCategoryDocument);
  const [ updateCategory, updateCategoryData ] = useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument);
  const [ creatingCategory, setCreatingCategory ] = React.useState(false);
  const [ categoriesPage, setCategoriesPage ] = React.useState(0);
  const [ newCategory, setNewCategory ] = React.useState<CategoryInput>({ name: "" });
  const [ deletingCategory, setDeletingCategory ] = React.useState(false);
  const [ selectedCategoryIds, setSelectedCategoryIds ] = React.useState<GridRowId[]>([]);

  // Error handling
  useErrorHandler(categoriesData, strings.errorHandling.category.list);
  useErrorHandler(createCategoryData, strings.errorHandling.category.create);
  useOperationErrorHandler(createCategoryData.data?.productCreate?.productErrors);
  useErrorHandler(deleteCategoryData, strings.errorHandling.category.delete);
  useOperationErrorHandler(deleteCategoryData.data?.productBulkDelete?.productErrors);
  useErrorHandler(updateCategoryData, strings.errorHandling.category.update);
  useOperationErrorHandler(updateCategoryData.data?.categoryUpdate?.productErrors);

  /**
   * Handler for category create confirm
   */
  const onAddCategoryConfirm = async () => {
    await createCategory({ variables: { input: newCategory } });

    setNewCategory({ name: "" });
    setCreatingCategory(false);
    categoriesData.refetch();
  };

  /**
   * Handler for category delete confirm
   */
  const onDeleteCategoryConfirm = async () => {
    await bulkDeleteCategory({ variables: { ids: selectedCategoryIds.map(id => id.toString()) } });

    setSelectedCategoryIds([]);
    categoriesData.refetch();
    setDeletingCategory(false);
  };

  /**
   * Handler for new category name change
   */
  const onNewCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCategory({ name: event.target.value });
  };

  /**
   * Handler for category change
   */
  const onCategoryChange = (updatedCategory: Category) => {
    updateCategory({ variables: { id: updatedCategory.id, input: { name: updatedCategory.name } } });
    categoriesData.refetch();
  };

  /**
   * Handler for categories page change
   */
  const onCategoriesPageChange = (newPage: number) => {
    const pageStartCursor = categoriesData.data?.categories?.pageInfo.startCursor;
    const pageEndCursor = categoriesData.data?.categories?.pageInfo.endCursor;

    if (categoriesPage > newPage) {
      // prev page
      // TODO investigate why fetchmore doesnt work and caching
      categoriesData.refetch({
        first: undefined, last: PRODUCT_CATEGORY_PAGE_SIZE, before: pageStartCursor, after: undefined
      });
    } else if (categoriesPage < newPage) {
      // next page
      categoriesData.refetch({
        first: PRODUCT_CATEGORY_PAGE_SIZE, last: undefined, after: pageEndCursor, before: undefined
      });
    }
    setCategoriesPage(newPage);
  };

  /**
   * Renders category data grid
   */
  const renderCategoryDataTable = () => {
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: strings.dashboard.category.dataGridColumn.name,
        width: 280,
        editable: true
      }
    ];

    const rows = categoriesData?.data?.categories?.edges.map(edge => (edge.node as Category)) || [];
    const categoryTotalCount = categoriesData?.data?.categories?.totalCount || 0;

    return (
      <Paper>
        <WithCategoryDataGridDebounce
          columns={ columns }
          rows={ rows }
          onRowChange={ onCategoryChange }
          component={ params =>
            <StyledDataGrid
              { ...params }
              disableSelectionOnClick
              checkboxSelection
              paginationMode="server"
              autoHeight
              onPageChange={ onCategoriesPageChange }
              pageSize={ PRODUCT_CATEGORY_PAGE_SIZE }
              rowCount={ categoryTotalCount }
              loading={ categoriesData.loading }
              onSelectionModelChange={ setSelectedCategoryIds }
            />
          }
        />
      </Paper>
    );
  };

  /**
   * Renders add category dialog
   */
  const renderAddCategoryDialog = () => {
    return (
      <GenericDialog
        error={ false }
        disabled={ !newCategory.name }
        open={ creatingCategory }
        onClose={ () => setCreatingCategory(false) }
        onCancel={ () => setCreatingCategory(false) }
        onConfirm={ onAddCategoryConfirm }
        title={ strings.dashboard.category.newCategoryDialog.title }
        positiveButtonText={ strings.generic.confirm }
        cancelButtonText={ strings.generic.cancel }
      >
        <Box p={ 1 }>
          <TextField
            fullWidth
            color="primary"
            value={ newCategory.name }
            name="name"
            label={ strings.dashboard.category.dataGridColumn.name }
            onChange={ onNewCategoryNameChange }
          />
        </Box>
      </GenericDialog>
    );
  };

  /**
   * Renders delete category dialog
   */
  const renderDeleteCategoryDialog = () => (
    <GenericDialog
      error={ false }
      open={ deletingCategory }
      onClose={ () => setDeletingCategory(false) }
      onCancel={ () => setDeletingCategory(false) }
      onConfirm={ onDeleteCategoryConfirm }
      title={ strings.dashboard.category.deleteCategoryDialog.title }
      positiveButtonText={ strings.generic.confirm }
      cancelButtonText={ strings.generic.cancel }
    >
      <Typography>
        { strings.dashboard.category.deleteCategoryDialog.text }
      </Typography>
    </GenericDialog>
  );

  /**
   * Component render 
   */
  return (
    <>
      <Stack
        spacing={ 2 }
        direction="row"
        justifyContent="space-between"
        marginBottom={ 2 }
      >
        <Typography variant="h2">
          { strings.dashboard.category.title }
        </Typography>
        <Box
          display="flex"
          alignItems="stretch"
        >
          <DashboardButton
            disabled={ !selectedCategoryIds.length }
            color="error"
            variant="outlined"
            startIcon={ <Delete/> }
            onClick={ () => setDeletingCategory(true) }
            sx={{ mr: 2 }}
          >
            { strings.dashboard.category.deleteCategory }
          </DashboardButton>
          <DashboardButton
            color="secondary"
            variant="contained"
            startIcon={ <Add/> }
            onClick={ () => setCreatingCategory(true) }
          >
            { strings.dashboard.category.createCategory }
          </DashboardButton>
        </Box>
      </Stack>
      { renderCategoryDataTable() }
      { renderAddCategoryDialog() }
      { renderDeleteCategoryDialog() }
    </>
  );
};

export default CategoriesView;