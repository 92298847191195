import { Toolbar, Box, AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * Styled header
 */
export const Root = styled(AppBar, {
  label: "header-root"
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff"
}));

/**
 * Styled logo container component
 */
export const LogoContainer = styled(Box, {
  label: "logo-container"
})(() => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center"
}));

/**
 * Styled logo container component
 */
export const Logo = styled("img", {
  label: "logo"
})(({ theme }) => ({
  maxHeight: 76,
  marginRight: theme.spacing(3),
  color: theme.palette.primary.main
}));

/**
 * Styled logo toolbar content component
 */
export const HeaderToolbar = styled(Toolbar, {
  label: "header-toolbar"
})(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `0px ${theme.spacing(5)} !important`
}));