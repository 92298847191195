import { Alert, Snackbar } from "@mui/material";
import { ErrorContext } from "components/error-handler/error-handler";
import React from "react";
import { useNavigate } from "react-router";
import { AuthenticationButton, AuthenticationInput, AuthenticationHelperText } from "styled/screens/authentication-screens";
import { CredentialError } from "types";
import AuthUtils from "utils/auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AuthenticationLayout from "components/layouts/authentication-layout";
import strings from "localization/strings";

/**
 * Register screen component
 */
const RegisterScreen: React.FC = () => {
  const navigate = useNavigate();
  const context = React.useContext(ErrorContext);
  const [ registerEmail, setRegisterEmail ] = React.useState("");
  const [ registerPassword, setRegisterPassword ] = React.useState("");
  const [ passwordRepeat, setPasswordRepeat ] = React.useState("");
  const [ emailHelperText, setEmailHelperText ] = React.useState("");
  const [ passwordHelperText, setPasswordHelperText ] = React.useState("");
  const [ passwordRepeatHelperText, setPasswordRepeatHelperText ] = React.useState("");
  const [ registerSuccessSnackBar, setRegisterSuccessSnackBar ] = React.useState(false);

  /**
   * Checks password and passwordRepeat
   * 
   * @param passwordInput password input
   * @param passwordRepeatInput password repeat input
   */
  const checkPasswordRepeat = (passwordInput: string, passwordRepeatInput: string) => {
    if (passwordInput !== passwordRepeatInput) {
      setPasswordRepeatHelperText(strings.authentication.passwordMismatch);
      return false;
    }
    setPasswordRepeatHelperText("");
    return true;
  };

  /**
   * Close register success snackBar handler
   */
  const clearUserInput = () => {
    setRegisterEmail("");
    setRegisterPassword("");
    setPasswordRepeat("");
  };

  /**
   * Sets error helper text
   * 
   * @param credentialErrors credential error array
   */
  const setRegisterErrors = (credentialErrors: CredentialError[]) => {
    const emailErrorText = credentialErrors.find(error => error.field?.toLowerCase() === "email")?.code || "";
    setEmailHelperText(emailErrorText);
  
    const passwordErrorText = credentialErrors.find(error => error.field?.toLowerCase() === "password")?.code || "";
    setPasswordHelperText(passwordErrorText);
  };

  /**
   * Renders helper text
   * 
   * @param helperText helper text
   */
  const renderRegisterHelperText = (helperText: string) => (
    <AuthenticationHelperText variant="h6">
      { helperText }
    </AuthenticationHelperText>
  );

  /**
   * Register button click handler
   */
  const onRegisterButtonClick = () => {
    if (!checkPasswordRepeat(registerPassword, passwordRepeat)) {
      return;
    }
    AuthUtils.register(registerEmail, registerPassword, `${window.location.origin}/account-confirm/`)
      .then(() => {
        clearUserInput();
        setRegisterSuccessSnackBar(true);
      }).catch(errors => {
        if (Array.isArray(errors)) {
          setRegisterErrors(errors as CredentialError[]);
        } else {
          context.setError(strings.errorHandling.registerFailed, errors);
        }
      });
  };

  /**
   * Close register success snackBar handler
   */
  const onRegisterSuccessClose = () => {
    setRegisterSuccessSnackBar(false);
  };

  /**
   * Password change event handler
   * 
   * @param event event
   */
  const onUserPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordInput = event.target.value;
    checkPasswordRepeat(passwordInput, passwordRepeat);
    setRegisterPassword(passwordInput);
  };

  /**
   * Password repeat change event handler
   * 
   * @param event event
   */
  const onUserPasswordRepeatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRepeatInput = event.target.value;
    checkPasswordRepeat(registerPassword, passwordRepeatInput);
    setPasswordRepeat(passwordRepeatInput);
  };

  /**
   * Renders register success snackBar
   */
  const renderRegisterSuccess = () => (
    <Snackbar
      open={ registerSuccessSnackBar }
      onClose={ onRegisterSuccessClose }
    >
      <Alert onClose={ onRegisterSuccessClose } severity="info">
        <span>{ strings.authentication.confirmationEmail }</span>
      </Alert>
    </Snackbar>
  );

  /**
   * Component render
   */
  return (
    <AuthenticationLayout>
      <AuthenticationInput
        value={ registerEmail }
        label={ strings.authentication.email }
        helperText={ renderRegisterHelperText(emailHelperText) }
        onChange={ event => setRegisterEmail(event.target.value) }
      />
      <AuthenticationInput
        type="password"
        label={ strings.authentication.password }
        value={ registerPassword }
        helperText={ renderRegisterHelperText(passwordHelperText) }
        onChange={ onUserPasswordChange }
      />
      <AuthenticationInput
        type="password"
        label={ strings.authentication.passwordRepeat }
        value={ passwordRepeat }
        helperText={ renderRegisterHelperText(passwordRepeatHelperText) }
        onChange={ onUserPasswordRepeatChange }
      />
      <AuthenticationButton
        color="secondary"
        variant="contained"
        onClick={ onRegisterButtonClick }
      >
        { strings.authentication.register }
      </AuthenticationButton>
      <AuthenticationButton
        color="primary"
        variant="outlined"
        startIcon={ <ArrowBackIcon/> }
        onClick={ () => navigate("/login") }
      >
        { strings.generic.back }
      </AuthenticationButton>
      { renderRegisterSuccess() }
    </AuthenticationLayout>
  );
};

export default RegisterScreen;