import React from "react";
import DashboardLayout from "components/layouts/dashboard-layout";
import { useAppSelector } from "app/hooks";
import { selectAccessToken } from "features/auth-slice";
import AuthUtils from "utils/auth";
import { useNavigate } from "react-router";
import DashboardRoutes from "./dashboard-routes";
import { LocalMall, ShoppingBag, Category } from "@mui/icons-material";
import { List } from "@mui/material";
import NavigationItem from "components/layout-components/navigation-item";
import strings from "localization/strings";
import { ContentContainer } from "styled/screens/dashboard-screen";

/**
 * Main screen component
 */
const DashBoardScreen: React.FC = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!accessToken || !AuthUtils.isStaff(accessToken)) {
      navigate("/");
    }
  }, [accessToken]);

  /**
   * Renders side penal component 
   */
  const renderNavigatePenal = () => (
    <List sx={{ width: 350 }}>
      <NavigationItem
        icon={ <LocalMall/> }
        to="products"
        title={ strings.dashboard.product.title }
      />
      <NavigationItem
        icon={ <ShoppingBag/> }
        to="productTypes"
        title={ strings.dashboard.productType.title }
      />
      <NavigationItem
        icon={ <Category/> }
        to="categories"
        title={ strings.dashboard.category.title }
      />
    </List>
  );

  return (
    <DashboardLayout
      sidePenal={ renderNavigatePenal() }
    >
      <ContentContainer>
        <DashboardRoutes/>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default DashBoardScreen;