import React from "react";
import { Root, Form, LogoContainer, Logo } from "styled/layouts/authentication-layout";
import siteLogo from "../../resources/svg/Metatavu-icon-black.svg";

/**
 * App layout component
 *
 * @param props component properties
 */
const AuthenticationLayout: React.FC = ({ children }) => (
  <Root>
    <LogoContainer>
      <Logo alt="Metatavu logo" src={ siteLogo }/>
    </LogoContainer>
    <Form>
      { children }
    </Form>
  </Root>
);

export default AuthenticationLayout;