import { Box, Typography } from "@mui/material";
import { ErrorContext } from "components/error-handler/error-handler";
import AuthenticationLayout from "components/layouts/authentication-layout";
import { login } from "features/auth-slice";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthenticationButton, AuthenticationInput, AuthenticationHelperText } from "styled/screens/authentication-screens";
import { CredentialError } from "types";
import AuthUtils from "utils/auth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import strings from "localization/strings";

/**
 * Login screen component
 */
const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = React.useContext(ErrorContext);
  const [ userEmail, setUserEmail ] = React.useState("");
  const [ userPassword, setUserPassword ] = React.useState("");
  const [ emailHelperText, setEmailHelperText ] = React.useState("");
  const [ passwordHelperText, setPasswordHelperText ] = React.useState("");

  /**
   * Set error helper text
   * 
   * @param loginErrors log in error array
   */
  const setLoginErrors = (loginErrors: CredentialError[]) => {
    const emailErrorText = loginErrors.find(error => error.field?.toLowerCase() === "email")?.message || "";
    setEmailHelperText(emailErrorText);
  
    const passwordErrorText = loginErrors.find(error => error.field?.toLowerCase() === "password")?.message || "";
    setPasswordHelperText(passwordErrorText);
  };

  /**
   * On login button click handler
   */
  const onLoginButtonClick = () => {
    AuthUtils.login(userEmail, userPassword)
      .then(result => {
        dispatch(login(result));
        navigate("/");
      })
      .catch(errors => {
        if (Array.isArray(errors)) {
          setLoginErrors(errors as CredentialError[]);
        } else {
          context.setError(strings.errorHandling.loginFailed, errors);
        }
      });
  };

  /**
   * Renders helper text
   * 
   * @param helperText helper text
   */
  const renderLoginHelperText = (helperText: string) => (
    <AuthenticationHelperText variant="h6">
      { helperText }
    </AuthenticationHelperText>
  );

  return (
    <AuthenticationLayout>
      <AuthenticationInput
        value={ userEmail }
        label={ strings.authentication.email }
        helperText={ renderLoginHelperText(emailHelperText) }
        onChange={ event => setUserEmail(event.target.value) }
      />
      <AuthenticationInput
        type="password"
        label={ strings.authentication.password }
        value={ userPassword }
        helperText={ renderLoginHelperText(passwordHelperText) }
        onChange={ event => setUserPassword(event.target.value) }
      />
      <AuthenticationButton
        type="submit"
        color="secondary"
        variant="contained"
        onClick={ onLoginButtonClick }
      >
        { strings.authentication.signIn }
      </AuthenticationButton>
      <AuthenticationButton
        color="primary"
        variant="outlined"
        startIcon={ <ArrowBackIcon/> }
        onClick={ () => navigate("/") }
      >
        { strings.generic.back }
      </AuthenticationButton>
      <Box
        display="flex"
        alignItems="center"
        mt={ 2 }
        flexDirection="column"
      >
        <Typography>
          { `${strings.loginScreen.newUser}? ` }
          <Link to="/register">
            { `${strings.loginScreen.registerNow}!` }
          </Link>
        </Typography>
        <Link to="/password-recovery">{ strings.loginScreen.forgotPassword }</Link>
      </Box>
    </AuthenticationLayout>
  );
};

export default LoginScreen;