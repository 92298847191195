import * as React from "react";
import { Route, Routes } from "react-router-dom";
import NewProductView from "./new-product-view";
import ProductView from "./product-view";

/**
 * Component for product routes
 */
const ProductRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/create"
        element={ <NewProductView/> }
      />
      <Route
        path="/:productSlug"
        element={ <ProductView/> }
      />
    </Routes>
  );
};

export default ProductRoutes;