import React from "react";
import AppLayout from "components/layouts/app-layout";
import { useQuery } from "@apollo/client";
import { ProductBySlugQuery, ProductBySlugDocument, ProductBySlugQueryVariables } from "api/api";
import { useErrorHandler } from "app/hooks";
import strings from "localization/strings";
import { useParams } from "react-router-dom";
import { Card, CardMedia, Stack, Typography, Paper, Button } from "@mui/material";

/**
 * Main screen component
 */
const ProductDetailScreen: React.FC = () => {
  const { productSlug } = useParams();
  const productData = useQuery<ProductBySlugQuery, ProductBySlugQueryVariables>(ProductBySlugDocument, { variables: { slug: productSlug || "" } });

  // Error handling
  useErrorHandler(productData, strings.errorHandling.product.list);

  /**
   * Renders product basic info
   */
  const renderBasicInfo = () => {
    if (!productData.data?.product) {
      return;
    }
    const { product } = productData.data;
    const productPrice = product.pricing?.priceRange?.start?.gross;

    // TODO product image
    return (
      <Paper>
        <Card sx={{ display: "flex", height: 400 }}>
          <CardMedia
            component="img"
            image=""
            alt=""
          />
          <Stack padding={ 4 } spacing={ 2 }>
            <Typography variant="subtitle1">
              { product.name }
            </Typography>
            <Typography>
              { product.description }
            </Typography>
            { productPrice &&
              <Typography variant="subtitle1">
                { `${productPrice.amount} ${productPrice.currency}` }
              </Typography>
            }
            {/* TODO purchase action */}
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => {} }
            >
              { strings.mainScreen.purchase }
            </Button>
          </Stack>
        </Card>
      </Paper>
    );
  };

  /**
   * Component render
   */
  return (
    <AppLayout>
      <Stack
        padding={ 12 }
        spacing={ 2 }
      >
        { renderBasicInfo() }
      </Stack>
    </AppLayout>
  );
};

export default ProductDetailScreen;