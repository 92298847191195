import { Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * Styled login textfield component
 */
export const AuthenticationInput = styled(TextField, {
  label: "authentication-screens-input"
})(({ theme }) => ({
  marginBottom: theme.spacing(0.5)
}));

/**
 * Styled login button component
 */
export const AuthenticationButton = styled(Button, {
  label: "authentication-screens-button"
})(({ theme }) => ({
  width: "100%",
  borderRadius: 20,
  marginTop: theme.spacing(1)
}));

/**
 * Styled login helper text component
 */
export const AuthenticationHelperText = styled(Typography, {
  label: "authentication-screen-helper-text"
})(({ theme }) => ({
  color: theme.palette.error.main
}));