import ProductTypesView from "components/dashboard/product-types-view";
import ProductsView from "components/dashboard/products-view";
import CategoryView from "components/dashboard/category-view";
import ProductRoutes from "components/dashboard/products/product-routes";
import * as React from "react";
import { Route, Routes } from "react-router-dom";

/**
 * Component for dashboard routes
 */
const DashboardRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/products"
        element={ <ProductsView/> }
      />
      <Route
        path="/products/*"
        element={ <ProductRoutes/> }
      />
      <Route
        path="/productTypes"
        element={ <ProductTypesView/> }
      />
      <Route
        path="/categories"
        element={ <CategoryView/> }
      />
    </Routes>
  );
};

export default DashboardRoutes;