import * as React from "react";
import { Button, Popover, Typography, List, ListItem } from "@mui/material";
import { PeronAvatar, Root } from "styled/generic/user-info";
import { logout, selectAccessToken } from "features/auth-slice";
import { useDispatch } from "react-redux";
import strings from "localization/strings";
import AuthUtils from "utils/auth";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";

/**
 * Component properties
 */
interface Props {
  userEmail: string;
}

/**
 * User info component
 *
 * @param props component properties
 */
const UserInfo: React.FC<Props> = ({ userEmail }) => {
  const dispatch = useDispatch();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement>();
  const accessToken = useAppSelector(selectAccessToken);
  const navigate = useNavigate();

  /**
   * Renders account popover 
   */
  const renderPopover = () => (
    <Popover
      open={ !!anchorEl }
      anchorEl={ anchorEl }
      onClose={ () => setAnchorEl(undefined) }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <List>
        <ListItem>
          <Button onClick={ () => dispatch(logout()) }>
            { strings.authentication.signOut }
          </Button>
        </ListItem>
        {
          AuthUtils.isStaff(accessToken) &&
          <ListItem>
            <Button onClick={ () => navigate("/dashboard") }>
              { strings.dashboard.title }
            </Button>
          </ListItem>
        }
      </List>
    </Popover>
  );

  return (
    <Root display="flex">
      <Button onClick={ event => setAnchorEl(event.currentTarget) }>
        <PeronAvatar
          alt={ userEmail }
        >
          { userEmail.charAt(0) }
        </PeronAvatar>
        <Typography
          variant="h5"
          sx={{
            pl: 2,
            color: "#000",
            my: "auto",
            textTransform: "none"
          }}
        >
          { userEmail }
        </Typography>
      </Button>
      { renderPopover() }
    </Root>
  );
};

export default UserInfo;