import { Toolbar, Box } from "@mui/material";
import Header from "components/layout-components/header";
import React from "react";
import { Content, StorefrontContainer } from "styled/layouts/app-layout";

/**
 * App layout component
 *
 * @param props component properties
 */
const AppLayout: React.FC = ({ children }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Header/>
      <Content>
        <Toolbar/>
        <StorefrontContainer>
          { children }
        </StorefrontContainer>
      </Content>
    </Box>
  );
};

export default AppLayout;