import { ListProductsDocument, Product, BulkDeleteProductDocument, ListProductsQuery, BulkDeleteProductMutation, ListProductsQueryVariables, BulkDeleteProductMutationVariables } from "api/api";
import * as React from "react";
import strings from "localization/strings";
import { useMutation, useQuery } from "@apollo/client";
import { DashboardButton, StyledDataGrid } from "styled/screens/dashboard-screen";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { GridColDef, GridRowId, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import GenericDialog from "components/generic/generic-dialog";
import ProductRoutes from "./products/product-routes";
import { useErrorHandler, useOperationErrorHandler } from "app/hooks";

const PRODUCT_TYPE_PAGE_SIZE = 20;

/**
 * Component for products view
 */
const ProductsView: React.FC = () => {
  const productsData = useQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, { variables: { first: PRODUCT_TYPE_PAGE_SIZE } });
  const [ bulkDeleteProduct, bulkDeleteProductData ] = useMutation<BulkDeleteProductMutation, BulkDeleteProductMutationVariables>(BulkDeleteProductDocument);
  const navigate = useNavigate();
  const [ productsPage, setProductsPage ] = React.useState(0);
  const [ selectedProductIds, setSelectedProductIds ] = React.useState<GridRowId[]>([]);
  const [ deletingProduct, setDeletingProduct ] = React.useState(false);

  // Error handling
  useErrorHandler(productsData, strings.errorHandling.product.list);
  useErrorHandler(bulkDeleteProductData, strings.errorHandling.product.delete);
  useOperationErrorHandler(bulkDeleteProductData.data?.productBulkDelete?.productErrors);

  /**
   * Handler for product page change
   */
  const onProductsPageChange = (newPage: number) => {
    const pageStartCursor = productsData.data?.products?.pageInfo.startCursor;
    const pageEndCursor = productsData.data?.products?.pageInfo.endCursor;

    if (productsPage > newPage) {
      // prev page
      // TODO investigate why fetchmore doesnt work and caching
      productsData.refetch({
        first: undefined, last: PRODUCT_TYPE_PAGE_SIZE, before: pageStartCursor, after: undefined
      });
    } else if (productsPage < newPage) {
      // next page
      productsData.refetch({
        first: PRODUCT_TYPE_PAGE_SIZE, last: undefined, after: pageEndCursor, before: undefined
      });
    }
    setProductsPage(newPage);
  };

  /**
   * Event handler for delete product confirm
   */
  const onDeleteProductConfirm = async () => {
    await bulkDeleteProduct({ variables: { ids: selectedProductIds.map(id => id.toString()) } });

    setSelectedProductIds([]);
    productsData.refetch();
    setDeletingProduct(false);
  };

  /**
   * Renders product data grid
   */
  const renderProductDataTable = () => {
    const columns: GridColDef[] = [
      {
        field: "Image",
        headerName: "",
        width: 50,
        renderCell: (params: GridRenderCellParams) => (
          params.value && <img src={ params.value.url } alt={ params.value.alt }/>
        ),
        sortable: false
      },
      {
        field: "name",
        headerName: strings.dashboard.product.dataGridColumn.name,
        width: 280
      },
      {
        field: "productType",
        headerName: strings.dashboard.product.dataGridColumn.productType,
        width: 280,
        renderCell: (params: GridRenderCellParams) => (
          params.value && <Typography>{ params.value.name }</Typography>
        )
      },
      {
        field: "category",
        headerName: strings.dashboard.product.dataGridColumn.category,
        width: 280,
        renderCell: (params: GridRenderCellParams) => (
          params.value && <Typography>{ params.value.name }</Typography>
        )
      },
      {
        field: "isPublished",
        type: "boolean",
        headerName: strings.dashboard.product.dataGridColumn.isPublished,
        width: 120
      },
      {
        field: "isAvailable",
        type: "boolean",
        headerName: strings.dashboard.product.dataGridColumn.isAvailable,
        width: 120
      },
      {
        field: "visibleInListings",
        type: "boolean",
        headerName: strings.dashboard.product.dataGridColumn.visibleInListings,
        width: 120
      }
    ];

    const rows = productsData?.data?.products?.edges.map(edge => (edge.node as Product)) || [];
    const productTotalCount = productsData?.data?.products?.totalCount || 0;

    return (
      <Paper>
        <StyledDataGrid
          autoHeight
          disableSelectionOnClick
          onRowClick={ params => navigate(params.row.slug) }
          columns={ columns }
          rows={ rows }
          checkboxSelection
          paginationMode="server"
          onPageChange={ onProductsPageChange }
          pageSize={ PRODUCT_TYPE_PAGE_SIZE }
          rowCount={ productTotalCount }
          loading={ productsData.loading }
          onSelectionModelChange={ setSelectedProductIds }
        />
      </Paper>
    );
  };

  /**
   * Renders delete product dialog
   */
  const renderDeleteProductDialog = () => (
    <GenericDialog
      error={ false }
      open={ deletingProduct }
      onClose={ () => setDeletingProduct(false) }
      onCancel={ () => setDeletingProduct(false) }
      onConfirm={ onDeleteProductConfirm }
      title={ strings.dashboard.product.deleteProductDialog.title }
      positiveButtonText={ strings.generic.confirm }
      cancelButtonText={ strings.generic.cancel }
    >
      <Typography>
        { strings.dashboard.product.deleteProductDialog.text }
      </Typography>
    </GenericDialog>
  );

  // TODO back button from container
  // redo the layout
  /**
   * Component render
   */
  return (
    <>
      <Stack
        spacing={ 2 }
        direction="row"
        justifyContent="space-between"
        marginBottom={ 2 }
      >
        <Typography variant="h2">
          { strings.dashboard.product.title }
        </Typography>
        <Box
          display="flex"
          alignItems="stretch"
        >
          <DashboardButton
            disabled={ !selectedProductIds.length }
            variant="outlined"
            color="error"
            startIcon={ <Delete/> }
            onClick={ () => setDeletingProduct(true) }
            sx={{ mr: 2 }}
          >
            { strings.dashboard.product.deleteProduct }
          </DashboardButton>
          <DashboardButton
            variant="contained"
            color="secondary"
            startIcon={ <Add/> }
            onClick={ () => navigate("create") }
          >
            { strings.dashboard.product.createProduct }
          </DashboardButton>
        </Box>
      </Stack>
      { renderProductDataTable() }
      { renderDeleteProductDialog() }
      <ProductRoutes/>
    </>
  );
};

export default ProductsView;