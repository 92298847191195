import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * Styled root component
 */
export const Root = styled(Box, {
  label: "app-layout-root"
})(() => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  overflow: "hidden"
}));

/**
 * Styled content component
 */
export const Content = styled(Box, {
  label: "app-layout-content"
})(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100vh"
}));

/**
 * Styled view container component
 */
export const ViewContainer = styled(Stack, {
  label: "app-layout-view-container"
})(({ theme }) => ({
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2)
  }
}));

/**
 * Styled storefront container
 */
export const StorefrontContainer = styled(Box, {
  label: "app-layout-storefront-container"
})(() => ({
  overflow: "scroll",
  height: "100%"
}));