import { CreateAccessTokenDocument, CreateAccessTokenMutationResult, CreateAccessTokenMutation, RegisterUserDocument, AccountRegisterInput, RegisterUserMutationResult, ConfirmAccountEmailDocument, ConfirmAccountEmailMutationResult, ResetPasswordDocument, ResetPasswordMutationResult, SetPasswordDocument, SetPasswordMutationResult, RefreshTokenDocument, RefreshTokenMutationResult, RefreshTokenMutation } from "api/api";
import { AuthState } from "features/auth-slice";
import { AccessToken, CredentialError } from "types";
import METASTORE_CSRF_TOKEN from "components/constant/constant";
import ApolloClientFactory from "app/graphql";

/**
 * Utility class for authentication
 */
export default class AuthUtils {

  /**
   * Initializes authentication flow
   */
  public static initAuth = async () : Promise<AccessToken | undefined> => {
    const csrfToken = localStorage.getItem(METASTORE_CSRF_TOKEN);

    if (!csrfToken) {
      return;
    }

    try {
      return await AuthUtils.refreshAccessToken(csrfToken);
    } catch (error) {
      localStorage.removeItem(METASTORE_CSRF_TOKEN);
      return Promise.reject(error);
    }
  };

  /**
   * Initializes set password flow
   */
  public static resetPassword = async (email: string, password: string, token: string) : Promise<void> => {
    try {
      const resetResult = await ApolloClientFactory.init().mutate({
        mutation: SetPasswordDocument,
        variables: {
          email: email,
          password: password,
          token: token
        }
      }) as SetPasswordMutationResult;

      const { data } = resetResult;

      if (data?.setPassword?.accountErrors.length) {
        return await Promise.reject(data?.setPassword?.accountErrors);
      }

      Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Initializes confirm account flow
   */
  public static passwordRecovery = async (email: string, redirectUrl: string) : Promise<void> => {
    try {
      const recoveryResult = await ApolloClientFactory.init().mutate({
        mutation: ResetPasswordDocument,
        variables: {
          email: email,
          redirectUrl: redirectUrl
        }
      }) as ResetPasswordMutationResult;

      const { data } = recoveryResult;

      if (data?.requestPasswordReset?.accountErrors.length) {
        return await Promise.reject(data?.requestPasswordReset?.accountErrors);
      }

      Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Initializes confirm account flow
   */
  public static confirmAccount = async (email: string, token: string) : Promise<void> => {
    try {
      const confirmResult = await ApolloClientFactory.init().mutate({
        mutation: ConfirmAccountEmailDocument,
        variables: {
          email: email,
          token: token
        }
      }) as ConfirmAccountEmailMutationResult;

      const { data } = confirmResult;

      if (data?.confirmAccount?.accountErrors.length) {
        return await Promise.reject(data?.confirmAccount?.accountErrors);
      }

      Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Initializes register flow
   */
  public static register = async (userEmail: string, userPassword: string, redirectUrl: string) : Promise<void> => {
    try {
      const registerInput: AccountRegisterInput = {
        email: userEmail,
        password: userPassword,
        redirectUrl: redirectUrl
      };

      const registerResult = await ApolloClientFactory.init().mutate({
        mutation: RegisterUserDocument,
        variables: {
          input: registerInput
        }
      }) as RegisterUserMutationResult;

      const { data } = registerResult;

      if (data?.accountRegister?.accountErrors.length) {
        return await Promise.reject(data?.accountRegister?.accountErrors);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Initializes log in
   *
   * @returns promise log in result
   */
  public static login = async (userEmail: string, userPassword: string) : Promise<AuthState> => {
    try {
      const authenticationResult = await ApolloClientFactory.init().mutate({
        mutation: CreateAccessTokenDocument,
        variables: {
          email: userEmail,
          password: userPassword
        }
      }) as CreateAccessTokenMutationResult;

      const { data } = authenticationResult;

      if (data?.tokenCreate?.errors.length) {
        return await Promise.reject(data?.tokenCreate?.errors);
      }

      if (!data?.tokenCreate?.token) {
        return { accessToken: undefined };
      }

      const accessToken = AuthUtils.buildToken(data);
      return { accessToken: accessToken };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Refreshes access token
   *
   * @param accessToken accessToken
   * @returns refreshed access token or undefined
   */
  public static refreshAccessToken = async (csrfToken?: string): Promise<AccessToken | undefined> => {
    if (!csrfToken) {
      return;
    }

    try {
      const refreshResult = await ApolloClientFactory.init().mutate({
        mutation: RefreshTokenDocument,
        variables: {
          csrfToken: csrfToken
        }
      }) as RefreshTokenMutationResult;

      const { data } = refreshResult;

      if (data?.tokenRefresh?.accountErrors.length) {
        return await Promise.reject(data?.tokenRefresh?.accountErrors);
      }

      if (!data?.tokenRefresh?.token) {
        return undefined;
      }

      const refreshedAccessToken = AuthUtils.buildTokenRefresh(data, undefined, csrfToken);
      return refreshedAccessToken;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Builds access token
   *
   * @param tokenData token data
   * @returns access token or undefined if building fails
   */
  public static buildToken = (tokenData: CreateAccessTokenMutation): AccessToken | undefined => {
    if (!tokenData.tokenCreate?.token) {
      return undefined;
    }

    return {
      created: new Date(),
      access_token: tokenData.tokenCreate.token,
      refresh_token: tokenData.tokenCreate.refreshToken || undefined,
      csrf_token: tokenData.tokenCreate.csrfToken || undefined,
      email: tokenData.tokenCreate.user?.email,
      userId: tokenData.tokenCreate.user?.id,
      isStaff: tokenData.tokenCreate.user?.isStaff
    };
  };

  /**
   * Builds access token
   *
   * @param tokenData token data
   * @returns access token or undefined if building fails
   */
  public static buildTokenRefresh = (tokenData: RefreshTokenMutation, refreshToken?: string, csrfToken?: string): AccessToken | undefined => {
    if (!tokenData.tokenRefresh?.token) {
      return undefined;
    }
  
    return {
      created: new Date(),
      access_token: tokenData.tokenRefresh.token,
      refresh_token: refreshToken || undefined,
      csrf_token: csrfToken || undefined,
      email: tokenData.tokenRefresh.user?.email,
      userId: tokenData.tokenRefresh.user?.id,
      isStaff: tokenData.tokenRefresh.user?.isStaff
    };
  };

  /**
   * Check if a user is admin
   *
   * @param accessToken access token
   * @returns boolean indicates if a user is admin
   */
  public static isStaff = (accessToken?: AccessToken): Boolean => {
    return !!accessToken?.isStaff;
  };

  /**
   * Serializes account confirmation errors
   * 
   * @param confirmationErrors confirmation error array
   */
  public static serializeConfirmationErrors = (confirmationErrors: CredentialError[]) => {
    return confirmationErrors
      .map(confirmationError => `${confirmationError.field}: ${confirmationError.code}`)
      .join(", ");
  };

}