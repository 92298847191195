import React from "react";
import { Box, Stack } from "@mui/material";
import AppLayout from "components/layouts/app-layout";
import { useQuery } from "@apollo/client";
import { ListProductsQuery, ListProductsDocument, ListProductsQueryVariables } from "api/api";
import { useErrorHandler } from "app/hooks";
import strings from "localization/strings";
import ProductCard from "components/generic/product-card";
import { useNavigate } from "react-router-dom";

/**
 * Main screen component
 */
const MainScreen: React.FC = () => {
  // TODO instead of product list, display category or collection
  const productsData = useQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, { variables: { first: 50, filter: { isPublished: true } } });
  const navigate = useNavigate();

  // Error handling
  useErrorHandler(productsData, strings.errorHandling.product.list);

  /**
   * Renders product cards
   */
  const renderProductCards = () => (
    <Stack direction="row" spacing={ 12 }>
      {
        productsData.data?.products?.edges.map(edge => (
          <ProductCard
            product={ edge.node }
            onClick={ () => navigate(edge.node.slug) }
          />
        ))
      }
    </Stack>
  );

  /**
   * Component render
   */
  return (
    <AppLayout>
      <Box p={ 10 }>
        { renderProductCards() }
      </Box>
    </AppLayout>
  );
};

export default MainScreen;