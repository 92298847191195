import * as React from "react";
import strings from "localization/strings";
import { useMutation, useQuery } from "@apollo/client";
import { CreateProductDocument, CreateProductMutation, CreateProductMutationVariables, ListCategoriesDocument, ListCategoriesQuery, ListCategoriesQueryVariables, ListProductTypesDocument, ListProductTypesQuery, ListProductTypesQueryVariables, ProductCreateInput } from "api/api";
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DashboardButton, DashboardCard } from "styled/screens/dashboard-screen";
import { useNavigate } from "react-router-dom";
import { useErrorHandler, useOperationErrorHandler } from "app/hooks";

const PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE = 3;

/**
 * Component for new product view
 */
const NewProductView: React.FC = () => {
  const navigate = useNavigate();
  const productTypesData = useQuery<ListProductTypesQuery, ListProductTypesQueryVariables>(ListProductTypesDocument, { variables: { first: PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE } });
  const categoriesData = useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, { variables: { first: PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE } });
  const [ createProduct, createProductData ] = useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument);
  const [ productTypeCount, setProductTypeCount ] = React.useState(PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE);
  const [ categoryCount, setCategoryCount ] = React.useState(PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE);
  const [ newProduct, setNewProduct ] = React.useState<ProductCreateInput>({
    name: "",
    description: "",
    productType: "",
    category: "",
    basePrice: 0,
    sku: ""
  });

  // Error handling
  useErrorHandler(productTypesData, strings.errorHandling.productType.list);
  useErrorHandler(createProductData, strings.errorHandling.product.create);
  useErrorHandler(categoriesData, strings.errorHandling.category.create);
  useOperationErrorHandler(createProductData.data?.productCreate?.productErrors);

  /**
   * validates new product
   */
  const validateNewProduct = () => {
    return newProduct.name && newProduct.productType;
  };

  /**
   * Event Handler set product prop
   */
  const onProductPropChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = ({ target }) => {
    const { value, name } = target;

    const updatedNewProduct = {
      ...newProduct,
      [name]: value
    };

    setNewProduct(updatedNewProduct);
  };

  /**
   * Graphql pagination load more product type
   */
  const onLoadMoreProductType = () => {
    const updatedProductTypeCount = productTypeCount + PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE;
    productTypesData.refetch({ first: updatedProductTypeCount });
    setProductTypeCount(updatedProductTypeCount);
  };

  /**
   * Graphql pagination load more categories
   */
  const onLoadMoreCategory = () => {
    const updatedCategoryCount = categoryCount + PRODUCT_SELECT_ATTRIBUTES_PAGE_SIZE;
    categoriesData.refetch({ first: updatedCategoryCount });
    setCategoryCount(updatedCategoryCount);
  };

  /**
   * Event Handler create new product confirm
   */
  const onCreateNewProductConfirm = async () => {
    await createProduct({ variables: { input: newProduct } });
    navigate(-1);
  };

  /**
   * Renders product type select
   */
  const renderProductTypeSelect = () => (
    productTypesData.data?.productTypes?.edges.map(edge => (
      <MenuItem value={ edge.node.id } key={ edge.node.id }>
        { edge.node.name }
      </MenuItem>
    ))
  );

  /**
   * Renders category select
   */
  const renderCategorySelect = () => (
    categoriesData.data?.categories?.edges.map(edge => (
      <MenuItem value={ edge.node.id } key={ edge.node.id }>
        { edge.node.name }
      </MenuItem>
    ))
  );

  /**
   * Renders load more product types button
   */
  const renderProductTypeLoadMore = () => (
    productTypesData.data?.productTypes?.pageInfo?.hasNextPage &&
    <Button
      fullWidth
      variant="text"
      onClick={ onLoadMoreProductType }
    >
      { strings.generic.loadMore }
    </Button>
  );

  /**
   * Renders load more categories button
   */
  const renderCategoryLoadMore = () => (
    categoriesData.data?.categories?.pageInfo?.hasNextPage &&
    <Button
      fullWidth
      variant="text"
      onClick={ onLoadMoreCategory }
    >
      { strings.generic.loadMore }
    </Button>
  );

  /**
   * Renders product editing section
   */
  const renderProductEdit = () => (
    <Stack spacing={ 3 }>
      <TextField
        sx={{ maxWidth: 300 }}
        name="name"
        label={ strings.dashboard.product.dataGridColumn.name }
        value={ newProduct.name }
        onChange={ onProductPropChange }
      />
      <TextField
        sx={{ maxWidth: 300 }}
        name="sku"
        label={ strings.dashboard.product.dataGridColumn.sku }
        value={ newProduct.sku }
        onChange={ onProductPropChange }
      />
      <TextField
        fullWidth
        multiline
        rows={ 6 }
        name="description"
        label={ strings.dashboard.product.dataGridColumn.description }
        value={ newProduct.description }
        onChange={ onProductPropChange }
      />
      <TextField
        sx={{ maxWidth: 300 }}
        select
        name="productType"
        label={ strings.dashboard.product.dataGridColumn.productType }
        value={ newProduct.productType }
        onChange={ onProductPropChange }
      >
        { renderProductTypeSelect() }
        { renderProductTypeLoadMore() }
      </TextField>
      <TextField
        sx={{ maxWidth: 300 }}
        select
        name="category"
        label={ strings.dashboard.product.dataGridColumn.category }
        value={ newProduct.category }
        onChange={ onProductPropChange }
      >
        { renderCategorySelect() }
        { renderCategoryLoadMore() }
      </TextField>
      <TextField
        sx={{ maxWidth: 300 }}
        type="number"
        name="basePrice"
        label={ strings.dashboard.product.dataGridColumn.basePrice }
        value={ newProduct.basePrice }
        onChange={ onProductPropChange }
      />
    </Stack>
  );

  /**
   * Renders buttons
   */
  const renderProductButtons = () => (
    <Stack direction="row" spacing={ 2 }>
      <DashboardButton
        sx={{ ml: "auto" }}
        variant="outlined"
        color="error"
        onClick={ () => navigate("../") }
      >
        { strings.generic.cancel }
      </DashboardButton>
      <DashboardButton
        disabled={ !validateNewProduct() }
        variant="contained"
        color="secondary"
        onClick={ onCreateNewProductConfirm }
      >
        { strings.generic.confirm }
      </DashboardButton>
    </Stack>
  );

  return (
    <DashboardCard>
      <Stack spacing={ 4 }>
        <Typography variant="h2">
          { strings.dashboard.product.newProduct.title }
        </Typography>
        { renderProductEdit() }
        { renderProductButtons() }
      </Stack>
    </DashboardCard>
  );
};

export default NewProductView;